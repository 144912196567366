<template>
  <ion-page>
    <ion-header class="headerTop ion-no-border">
      <headertoolbar></headertoolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <div class="original_main_container">
        <ion-header collapse="condense"></ion-header>
        <ion-icon class="l_close_a" button @click="this.$router.push('/admin/dashboard')"
          src="assets/ICONOS/close.svg"></ion-icon>

              <p class="tabTitle">Logs de Reproducción
                <ion-icon class="config_icon_question" src="assets/ICONOS/questionmark.svg" 
                    @click="openModal('Logs de Reproducción', 'Son una lista de los audios y videos que se han reproducido en cada sucursal de su canal. Estos registros se guardan y están disponibles para que pueda revisar cualquier problema en la secuencia de reproducción generada por el sistema Vybroo.', 

[
  {
    id: 0,
    mrg: 10,
    name: 'Para usarlos, simplemente seleccione la fecha y la sucursal que desea consultar.'
  },
  {
    id: 1,
    mrg: 10,
    name: 'La tabla incluye una función de filtrado que facilita la búsqueda de contenidos específicos.'
  },
  {
    id: 2,
    mrg: 10,
    name: 'Base se refiere a la reproducción de sucursales que no están identificadas con su nombre (Si no tiene sucursales de contenido, su canal siempre estará en Base).'
  }
])"
                    />
              </p>

                <select class="channel_select_e" v-model="selectedChannel" @change="handleChannelChange" aria-label="Canal" interface="popover" placeholder="Selecciona el canal">
                  <option v-for="item in channel_name_list" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </option>
                </select>

                <ion-grid class="log_details_g">
                  <ion-row>
                    <ion-col class="log_date_div">
                        <ion-datetime v-model="date" class="log_date_select" @ionChange="handleDateChange" :min="minDate" :max="maxDate" display-format="DD/MMM/YYYY" picker-format="DD/MM/YYYY" cancel-text="Cancelar" done-text="Aceptar"></ion-datetime>
                        
                    </ion-col>
                    <ion-col class="log_select_div">
                      <select class="log_select_e" v-model="selectedlog" @change="handlelogChange" aria-label="Canal" interface="popover" placeholder="Selecciona el canal">
                          <option v-for="item in log_list" :key="item.id" :value="item">
                              {{ item.variant_name }} | {{ item.music_variant_name }}
                          </option>
                    </select>
                    </ion-col>
                  </ion-row>
                </ion-grid>
                
                <ion-card v-if="log_list.length<=0 && isloading == false" color="warning" class="connectionalert outline-card" ouline>
                  <ion-card-header>
                    <ion-card-title>Sin Registros.</ion-card-title>
                  </ion-card-header>
                  <ion-card-content>
                    No existen registros para la fecha seleccionada.
                  </ion-card-content>
                </ion-card>

                <div class="out_loader" v-if="isloading">
                  <div class="loader"></div>
                </div>

                <div class="log_count_l" v-show="log_table.length > 0 && !isloading && log_list.length > 0">
                  Registros mostrados: {{ filteredLogs.length }} / {{ log_table.length }}
                </div>
                <div class="filter-input-con" v-show="log_table.length > 0 && !isloading && log_list.length > 0">
                  <ion-input class="filterInput" placeholder="Filtrar por cualquier cosa" @keyup="filterLogs" ref="textFilter"></ion-input>
                  <ion-icon class="filterIcon filtersearch" src="assets/ICONOS/search.svg"></ion-icon>
                  <ion-icon src="assets/ICONOS/close_f.svg" class="filterIcon filterclose" @click="this.$refs.textFilter.value = ''; filterLogs();"></ion-icon>
                </div>
                <div class="log_count_r"  v-show="log_table.length > 0 && !isloading && log_list.length > 0">
                  Registros mostrados: {{ filteredLogs.length }} / {{ log_table.length }}
                </div>

                <table v-if="log_list.length>0 && log_table.length>0 && isloading == false" class="log_table">
                  <thead>
                    <tr>
                      <th class="token">Token</th>
                      <th class="hora">Hora</th>
                      <th class="type">Tipo</th>
                      <th class="categoryfiltro">Categoría/Filtro</th>
                      <th class="formula">Fórmula</th>
                      <th class="title">Título</th>
                      <th class="artist">Artista</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in filteredLogs" :key="item.token">
                      <td>{{ item.token }}</td>
                      <td>{{ item.hora }}</td>
                      <td :class="'t_type t_type_'+item.type">{{ item.type }}</td>
                      <td>{{ item.category }}{{ item.filter }}</td>
                      <td>{{ item.formula }}</td>
                      <td>{{ item.title }}</td>
                      <td>{{ item.artist }}</td>
                    </tr>
                  </tbody>
                </table>
                <br>
                <br>
                <br>
                
      </div>
    </ion-content>
    <!-- <ion-button @click="signOut" class="logoutButtonCabina"><ion-icon src="assets/ICONOS/cabina/log-out-outline.svg"></ion-icon>Cerrar sesión</ion-button> -->
  </ion-page>
</template>


<script>
/* eslint-disable */
import { alertController, IonPage, IonHeader, IonToolbar, IonContent, IonRefresher, IonRefresherContent, IonDatetime, modalController } from '@ionic/vue';
import axios from 'axios';
import infoModal from '../infoModal.vue';
import { defineComponent } from 'vue';
import { Storage } from '@capacitor/storage';
import headertoolbar from '../header_toolbar.vue';
import { Capacitor } from '@capacitor/core';
import 'url-polyfill';
import 'blob-polyfill'; 
import { max, min } from 'moment-timezone';
import moment from 'moment';

//import { toastController } from '@ionic/vue';

export default ({
  name: 'Reproductor',
  components: { IonHeader, IonToolbar, IonContent, IonPage, headertoolbar, IonRefresher, IonRefresherContent, IonDatetime },
  data() {
    return {
      urlApi: 'https://panel.vybroo.net/',
      clientData: null,  
      token: null,
      serverDate: null,
      clientData: {}, 
      channel_name_list: [],
      channel_list: [],
      selectedChannel: null,
      
      log_list: [],
      selectedlog: null,
      isloading: false,
      dashboard: 
        {
          client: {
            email: "",
            id: 0,
            name: "",
            obligation: 0,
            retainer: 0
          },
          radio: {
            blacklist: "",
            branches: 0,
            categories: "",
            image: "2f07f7cfc8d4348937078a566c7d943e.png",
            link: "",
            mobileimage: "6b8b53effc9683373db10671d4bf0687.png",
            name: "",
            tags_blacklist: "",
            video_enabled: false
          },
          spots: {
            id: 0,
            level: 0,
            month: "",
            name: "",
            obligation: 0,
            percentage: "",
            produced: null,
            year: ""
          },
        },
        date: moment().format('YYYY-MM-DD'),
        minDate: moment().subtract(31, 'days').format('YYYY-MM-DD'),
        maxDate: moment().format('YYYY-MM-DD'),
        log_table: [],
        filteredLogs: [],

    }
  },
  methods: { 
    async openModal(title, desc, list) {
      const modal = await modalController.create({
        component: infoModal,
        componentProps: {
          title: title,
          desc: desc,
          list: list,
        },
      });
      return modal.present();
    },
    filterLogs() {
      let filter = this.$refs.textFilter.value.toLowerCase();
      this.filteredLogs = [];
      this.filteredLogs = this.log_table.filter(log => {
        return log.token.toLowerCase().includes(filter) ||
          log.hora.toLowerCase().includes(filter) ||
          log.type.toLowerCase().includes(filter) ||
          log.category.toLowerCase().includes(filter) ||
          log.filter.toLowerCase().includes(filter) ||
          log.formula.toLowerCase().includes(filter) ||
          log.title.toLowerCase().includes(filter) ||
          log.artist.toLowerCase().includes(filter);
      }); 
    },
    handleChannelChange(event) { 
      this.selectedChannel = event.target.value;
      this.emitter.emit("selectedChannel", event.target.value);
              Storage.set({
                key: "cabina_admin_selectedChannel",
                value: this.selectedChannel,
              }); 
      setTimeout(() => {
        this.getLogs();
      }, 1000);
    },
    handleDateChange() {
      console.log("handleDateChange::Obteniendo handleDateChange...");
      setTimeout(() => {
        this.getLogs();
      }, 1000);
    },
    handlelogChange(event) {
      setTimeout(() => {
        this.getListLogs();
      }, 1000);
    },
    async getListLogs() {
      try {
        console.log("getListLogs::Obteniendo getListLogs...");
        //https://panel.vybroo.net/get_log_app
        try {
          
          //console.log("revisando validez de token: " + token + " ...");
          if (this.token == null || this.token == undefined || this.token == "") {
            this.presentAlertTokenInvalid();
            return false;
          }
          if (this.token != null && this.token != undefined && this.token != "") {
            let url = "api/get_log_app";
            if (Capacitor.getPlatform() == 'ios') url = "https://panel.vybroo.net/get_log_app";
            let data = {
              token: this.token,
              when: moment(this.date).format('YYYY-MM-DD'),
              channel: this.selectedChannel,
              stream: this.selectedlog.stream,
              variant: this.selectedlog.variant,
              music_variant: this.selectedlog.music_variant,
              type: this.dashboard.radio.video_enabled ? 1 : 0
            };
            let response = await axios.post(url, data)
              .then(r => {
                console.log("r.data.getListLogs:",r.data); 
 
                this.log_table = [];
                var log_row2 = r.data.log_array;
                log_row2.forEach(log_row => {
                  let logObject = {
                    token: log_row.token,
                    when: log_row.when,
                    hora: log_row.hora,
                    type: log_row.type,
                    playlist: log_row.playlist,
                    category: log_row.category,
                    filter: log_row.filter,
                    formula: log_row.formula,
                    title: log_row.title,
                    artist: log_row.artist
                  };
                  if (log_row.token != 0) {
                    if (log_row.token != 0) {
                      if (!this.log_table.some(log => log.token === log_row.token)) {
                        this.log_table.push(logObject);
                      }
                    }
                  }
                });
                setTimeout(() => {
                  this.filterLogs();
                }, 500);
 
              }).catch(error => {
                console.log("3Error en función validateToken de getListLogs: " + error.message);
                return error;
              });
            console.log("response.data:",response.data);
            //console.log("response.data.success: " + response.data.success);
            //console.log("response.data.error: " + response.data.error);
          }
        } catch (error) {
          //console.log("4Error en función validateToken de getListLogs: " + error.message);
          return false;
        }

      } catch (error) {
        console.log("Error en función getListLogs de getListLogs: " + error.message);
      }
    },
    async getLogs() {
      this.isloading = true;
      this.log_table = [];
      try {
        console.log("getLogs::Obteniendo conexiones...");
        //https://panel.vybroo.net/get_available_logs
        try {
          
          //console.log("revisando validez de token: " + token + " ...");
          if (this.token == null || this.token == undefined || this.token == "") {
            this.presentAlertTokenInvalid();
            return false;
          }
          if (this.token != null && this.token != undefined && this.token != "") {
            let url = "api/get_available_logs_app";
            if (Capacitor.getPlatform() == 'ios') url = "https://panel.vybroo.net/get_available_logs_app";
            let response = await axios.post(url, { 
              token: this.token,
                when: moment(this.date).format('YYYY-MM-DD'),
              channel: this.selectedChannel
            })
              .then(r => {
                console.log("r.data:",r.data.items);
                this.log_list = r.data.items;
                if (this.selectedlog) { 
                var auxSelectedLog = this.selectedlog;
                let index = this.log_list.findIndex(log => 
                  log.id === auxSelectedLog.id &&
                  log.stream === auxSelectedLog.stream &&
                  log.variant === auxSelectedLog.variant &&
                  log.variant_name === auxSelectedLog.variant_name &&
                  log.music_variant === auxSelectedLog.music_variant &&
                  log.music_variant_name === auxSelectedLog.music_variant_name
                );
                this.selectedlog = index !== -1 ? this.log_list[index] : this.log_list[0];
                this.log_list.sort((a, b) => {
                  if (a.variant_name === 'Base') return -1;
                  if (b.variant_name === 'Base') return 1;
                  return a.variant_name.localeCompare(b.variant_name);
                });

                setTimeout(() => {
                  this.getListLogs();
                }, 500);
                } else {
                  this.selectedlog = this.log_list[0];
                }
                this.isloading = false;
              }).catch(error => {
                console.log("3Error en función validateToken de Conexiones: " + error.message);
                this.isloading = false;
                return error;
              });
            console.log("response.data:",response.data);
            //console.log("response.data.success: " + response.data.success);
            //console.log("response.data.error: " + response.data.error);
          }
        } catch (error) {
          //console.log("4Error en función validateToken de Conexiones: " + error.message);
          return false;
        }

      } catch (error) {
        console.log("Error en función getLogs de Conexiones: " + error.message);
      }
    },
    async presentAlertConfirm(headerAlert, messageAlert, buttonTextConfirm, buttonTextCancel, functionConfirm, functionCancel) {
      const alert = await alertController
        .create({
          header: headerAlert,
          message: messageAlert,
          buttons: [
            {
              text: buttonTextConfirm,
              handler: () => {
                functionConfirm();
              }
            },
            {
              text: buttonTextCancel,
              handler: () => {
                functionCancel();
              }
            }
          ]
        });
      return alert.present();
    },
    async signOut() {
      const alert = await alertController
        .create({
          header: "Aviso",
          message: "¿Estás seguro que deseas cerrar la sesión?",
          buttons: [
            {
              text: 'Si',
              handler: () => {
                Storage.remove({ key: 'authToken' });
                Storage.remove({ key: 'cabina_admin_user' });
                Storage.remove({ key: 'cabina_admin_channel_list' });
                Storage.remove({ key: 'cabina_admin_selectedChannel' });
                this.$router.replace("/admin/LoginAdmin");
                this.emitter.emit('cabina_admin_user', '',);
                this.emitter.emit('cabina_admin_channel_list', '',);
              }
            },
            {
              text: 'Cancelar',
              handler: () => {
                //console.log('Cancelar pulsado');
              }
            },
          ]
        });
      return alert.present();

    }, 
    async getStorageClientData(keyName) {
      try {
        const ret = await Storage.get({ key: keyName });
        this.clientData = this.isJson(ret.value);
      } catch (error) {
        //console.log("Error en función getStorageClientData de Conexiones: " + error.message);
      }
    }, 
    isJson(str) {
      try {
        const parsedStr = JSON.parse(str);
        return parsedStr;
      } catch (e) {
        return str;
      }
    },
    async getStorageAuthToken() {
      try {
        //console.log("Obteniendo token del storage...")
        const ret = await Storage.get({ key: 'authToken' });
        if (ret.value) {
          const tokenObj = JSON.parse(ret.value);
          this.token = tokenObj.value;
          //console.log("Token obtenido: " + this.token);
          // console.log("Token obtenido:" + this.token)
          return this.validateToken(this.token);
        } else {
          //console.log("Token no encontrado");
          this.presentAlertTokenInvalid();
          return false;
        }
      } catch (error) {
        //console.log("Error en función getStorageAuthToken de Conexiones: " + error.message);
      }
    },
    async presentAlertTokenInvalid() {
      const alert = await alertController
        .create({
          header: "Aviso",
          message: "Tu sesión ha expirado, por favor inicia sesión nuevamente",
          buttons: [
            {
              text: 'Aceptar',
              handler: () => {
                this.$router.replace("/admin/LoginAdmin");
              }
            },
          ]
        });
      return alert.present();
    },
    async validateToken(token) {
      try {
        //console.log("revisando validez de token: " + token + " ...");
        if (token == null || token == undefined || token == "") {
          this.presentAlertTokenInvalid();
          return false;
        }
        if (token != null && token != undefined && token != "") {
          let url = "api/user_auth";
          if (Capacitor.getPlatform() == 'ios') url = "https://panel.vybroo.net/user_auth";
          let response = await axios.post(url, { token: token })
            .then(r => {
              //console.log("r.data:");
              //console.log(r.data);
              return r;
            }).catch(error => {
              //console.log("3Error en función validateToken de Conexiones: " + error.message);
              return error;
            });
          //console.log("response.data:");
          //console.log(response.data);
          //console.log("response.data.success: " + response.data.success);
          //console.log("response.data.error: " + response.data.error);
          if (response.data.error === true) {
            //console.log("Error al validar token:");
            //console.log(response.data);
            this.presentAlertTokenInvalid();
            return false;
          }
          if (response.data.success === true) {
            //console.log("Token válido");
            return true;
          } else {
            //console.log("Token inválido");
            //console.log("2response.data.success: " + response.data.success);
            //console.log("2response.data.error: " + response.data.error);
            this.presentAlertTokenInvalid();
            return false;
          }
        }
      } catch (error) {
        //console.log("4Error en función validateToken de Conexiones: " + error.message);
        return false;
      }
    },
    async getFullClientData() {
      try {
        const ret = await Storage.get({ key: "clientData" });
        if (ret.value) {
          this.clientData = JSON.parse(ret.value);
          //console.log("Recuperado de storage, key: clientData - " + this.clientData);

          if (this.clientData.sonara) {
            this.emitter.emit("sonara", true);
          } else {
            this.emitter.emit("sonara", false);
          }
        } else {
          //console.log("No se pudo recuperar de storage, key: clientData - " + this.clientData);
        }
      } catch (e) {
        this.presentAlertConfirm("Ha ocurrido un error en la función getFullClientData", e);
      }
    },
    async getChannelList(){
      try {
        const ret = await Storage.get({ key: "cabina_admin_channel_list" });
        if (ret.value) { 
            this.channel_list = JSON.parse(ret.value).slice();
            this.emitter.emit("channel_list", this.channel_list);
            this.channel_name_list = [];
            if (this.channel_list){
              this.channel_list.forEach(element => {
                this.channel_name_list.push({ id: element.id, name: element.name });
              });
              this.emitter.emit("channel_name_list", this.channel_name_list);
              //console.log("Recuperado de storage, key: cabina_admin_channel_list - " , this.channel_list);
              //console.log("Recuperado de storage, key: channel_name_list - " , this.channel_name_list);
              if (this.selectedChannel == [] || this.selectedChannel == null || this.selectedChannel == undefined) {
                const selectedChannelAux = await Storage.get({ key: "cabina_admin_selectedChannel" });
                //console.log("selectedChannelAux: " , selectedChannelAux);
                if (selectedChannelAux.value) {
                  this.selectedChannel = selectedChannelAux.value;
                  this.emitter.emit("selectedChannel", this.selectedChannel);
                  Storage.set({
                    key: "cabina_admin_selectedChannel",
                    value: this.selectedChannel,
                  });
                } else {
                  this.selectedChannel = this.channel_list[0].id;
                  this.loadChannelDashboard(this.selectedChannel);
                  this.emitter.emit("selectedChannel", this.selectedChannel);
                  Storage.set({
                    key: "cabina_admin_selectedChannel",
                    value: this.selectedChannel,
                  });
                }
                //this.selectedChannel = this.channel_list[0].id;
                //this.loadChannelDashboard(this.selectedChannel);
                //this.emitter.emit("selectedChannel", this.selectedChannel);
              }
            }
        } else {
          console.log("No se pudo recuperar de storage, key: cabina_admin_channel_list - " , this.channel_list);
        }
      } catch (error) {
        console.log("Error en función cabina_admin_channel_list de Dashboard: " , error.message);
        return false;
      }
    }
  },
  mounted: function () { 
setTimeout(() => {

    try {
      if (Capacitor.getPlatform() == 'ios') {
        this.urlApi = "https://panel.vybroo.net/";
      } else {
        this.urlApi = "/api/";
      } 

      //get auth token de storage y asignarlo a la variable token
      this.getStorageAuthToken();
      this.getStorageClientData('clientData');
      this.getChannelList();

      this.emitter.on("selectedChannel", (channel) => {
        //console.log("conexiones::::emitedON:selectedChannel: " , channel);
        this.selectedChannel = channel;
        this.getLogs(); 
      });
      this.emitter.on("channel_list", (channel_list) => {
        //console.log("conexiones::::emitedON:channel_list: " , channel_list);
        this.channel_list = channel_list;
      });
      this.emitter.on("channel_name_list", (channel_name_list) => {
        //console.log("conexiones::::emitedON:channel_name_list: " , channel_name_list);
        this.channel_name_list = channel_name_list;
      });
      this.emitter.on("dashboard", (dashboard) => {
        //console.log("conexiones::::emitedON:dashboard: " , dashboard);
        this.dashboard = dashboard; 
      });
 
    } catch (error) {
      console.log("Error en función mounted de Conexiones: " + error.message);
    }

     
}, 1000);
  setTimeout(() => {
    this.getLogs(); 
  }, 2000);
  },
  

})
</script>
<style lang="css"> 
.log_select_e {
    width: 100% ;
    margin: 0 0 0 16px;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px #232323;
    background-color: #000000;
    color: #ffffff;
    font-size: 1em;
    font-weight: 400;
    margin-bottom: 10px;
}
.log_date_select {
    width: 100%;
    margin: 0 0 0 8px;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px #232323;
    background-color: #000000;
    color: #ffffff;
    font-size: 1em;
    font-weight: 400;
    margin-bottom: 10px;
}
.log_date_div{
  width: 140px !important;
  max-width: 140px !important;
  min-width: 140px !important;
}
.log_select_div{
  width: calc(100% - 160px) !important;
  max-width: calc(100% - 160px) !important;
  min-width: calc(100% - 160px) !important;
}

.log_details_g{ 
  padding: 0px !important;
}

.filter-input-con {   
  margin: 10px 10px;
  border: solid 1px #232323;
  position: relative;
  border-radius: 5px;
  max-width: calc(100% - 22px);
  width: calc(400px - 35px);
}
.filter-input-con .filterInput {
  width: 100%;  
}
.filter-input-con .filterInput input{
  padding-left: 35px;
}
.filter-input-con .filtersearch {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}
.filter-input-con .filterclose {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  cursor: pointer;
  z-index: 2;
}


.log_count_l {
  position: relative;
    border-radius: 5px;
    width: 280px;
    float: right;
    text-align: right;
    margin-top: 25px;
    display: inherit;
    padding: 0px 10px;
}
.log_count_r {
    position: relative;
    border-radius: 5px;
    width: 100%;
    float: inherit;
    text-align: right;
    margin: 10px auto;
    padding: 0px 10px;
    display: none;
}
.log_table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  font-size: 1em;
  font-weight: 400;
  color: #ffffff;
  background-color: #000000;
  border-radius: 5px;
  border: solid 1px #232323;
}
.log_table th {
  padding: 5px;
  border-bottom: solid 1px #232323;
  text-align: left;
}
.log_table td {
  padding: 5px;
  border-bottom: solid 1px #232323;
  text-align: left;
}
.log_table tr:hover{
  background-color: #232323;
}
.log_table th.token { 
}
.log_table th.hora {
  width: 80px;
}
.log_table th.type {
  width: 50px;
}
.log_table th.categoryfiltro {
 
}
.log_table th.formula {
 
}
.log_table th.title {
 
}
.log_table th.artist {
 
}
.log_table td.t_type {
  text-align: center;
}
.log_table td.t_type.t_type_song {
  background-color: #00b57a;
}
.log_table td.t_type.t_type_spot {
  background-color: #00779b;
}
.log_table td.t_type.t_type_time {
  background-color: #ba02c7;
}

@media (max-width: 767px) {
  .log_table {
    width: 100%;
        max-width: 100%;
        height: calc(100vh - 400px);
        overflow: auto !important;
        display: block;
  }
  .log_table th {
    position: sticky;
    top: 0;
    background-color: #000000;
    z-index: 1;
  }
  .log_count_l {
    display: none;
  }
  .log_count_r {
      display: inherit;
  }
}
</style>